var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0 pt-0"},[_c('v-card',{staticClass:"data-table",attrs:{"elevation":"4"}},[_c('v-data-table',{staticClass:"elevation-0 custom-data-table",attrs:{"calculate-widths":"","headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"sort-by":_vm.sortByComputed.value,"sort-desc":_vm.sortDesc,"hide-default-footer":"","hide-default-header":"","loading":_vm.itemsLoading},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm.page=$event},"update:sortBy":function($event){return _vm.$set(_vm.sortByComputed, "value", $event)},"update:sort-by":function($event){return _vm.$set(_vm.sortByComputed, "value", $event)},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var isMobile = ref.isMobile;
var props = ref.props;
var on = ref.on;
return [_c('table-custom-sort',_vm._g({attrs:{"isMobile":isMobile,"props":props,"v-on":on,"canBeSelected":false,"allSelected":_vm.allSelected},on:{"selectAll":_vm.handleSelectAll}},_vm.$listeners))]}},{key:"item.name",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"item-bordered",class:[item.featured ? 'item-bordered--yellow' : '']},[_c('div',{staticClass:"d-flex flex-column text-truncate"},[(item.featured)?_c('span',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"font-weight-bold text-truncate d-block flex-start",staticStyle:{"max-width":"415px"},domProps:{"innerHTML":_vm._s(item.name)}}),_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99","offset-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 primary-on-hover",attrs:{"color":"gray lighten-1","size":"16"}},'v-icon',attrs,false),on),[_vm._v(" $alertcircle ")])]}}],null,true)},[_c('span',{staticClass:"base-text"},[_vm._v(_vm._s(_vm.$t('tooltip.instance.plugin.add.special')))])]),_c('div',{staticClass:"featured-chip ml-2"},[_vm._v(_vm._s(_vm.$t('general.featured')))])],1):_c('span',{staticClass:"font-weight-bold text-truncate",staticStyle:{"max-width":"450px"},domProps:{"innerHTML":_vm._s(item.name)}}),_c('div',{staticClass:"d-flex"},[_c('div',{ref:("text-" + index),staticClass:"text-truncate",staticStyle:{"max-width":"450px"},domProps:{"innerHTML":_vm._s(_vm.getDescription(item.short_description || item.description))}}),(item.description.length > 100)?_c('a',{staticClass:"info--text font-weight-bold ml-1",on:{"click":function($event){return _vm.$emit('action-button-read-more', item)}}},[_vm._v(_vm._s(_vm.$t('button.readMore')))]):_vm._e()])])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex actions-row"},[_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99","offset-overflow":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-auto",staticStyle:{"min-width":"38px","max-width":"38px"},attrs:{"color":"primary","small":"","elevation":"0"},nativeOn:{"click":function($event){return _vm.$emit('action-button-install', item)}}},'v-btn',attrs,false),on),[(
                    !_vm.loading &&
                    !item.hasInstallInProgress() &&
                    !item.hasUpdateInProgress()
                  )?_c('v-icon',{staticClass:"mr-0",attrs:{"size":"18","color":"white"}},[_vm._v(" $installIcon ")]):_c('loader',{staticClass:"mr-0",attrs:{"color":"white","size":"24","noMargins":true}})],1)]}}],null,true)},[_c('span',{staticClass:"base--text"},[_vm._v(_vm._s(_vm.$t('button.plugin.install')))])])],1)]}},{key:"item.version",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[(item.update !== 'available')?_c('v-icon',{attrs:{"color":"primary","size":"16"}},[_vm._v(" $alertok ")]):_c('v-icon',{attrs:{"color":"error","size":"16"}},[_vm._v("$alertwarning")]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.version))])],1)]}},{key:"item.rating",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center p-4"},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"20","percent":item.rating / 100}},[_vm._v("$starIcon")]),_c('b',{},[_vm._v(_vm._s(Math.ceil((item.rating / 10) * 5) / 10))])],1)]}},{key:"item.active_installs",fn:function(ref){
                  var item = ref.item;
return [(item.active_installs)?_c('div',{staticClass:"d-flex align-center p-4",attrs:{"elevation":"0"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"20","color":"gray lighten-1"}},[_vm._v("$installIcon")]),_c('b',{},[_vm._v(_vm._s(item.active_installs)+"+")])],1):_vm._e()]}},{key:"item.tested",fn:function(ref){
                  var item = ref.item;
return [(item.tested)?_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"20","color":"gray lighten-1"}},[_vm._v("$installIcon")]),_vm._v(" "+_vm._s(item.tested)+" ")],1):_vm._e()]}},{key:"no-data",fn:function(){return [(_vm.searchTerm)?_c('data-iterator-no-results-container',{attrs:{"searchTerm":_vm.searchTerm},on:{"clearAllFilters":function($event){return _vm.$emit('clearAllFilters')}}}):_vm._e()]},proxy:true},{key:"no-results",fn:function(){return [_c('data-iterator-no-results-container',{attrs:{"searchTerm":_vm.searchTerm},on:{"clearAllFilters":function($event){return _vm.$emit('clearAllFilters')}}})]},proxy:true},{key:"footer",fn:function(){return [_c('div',[_c('data-iterator-footer',_vm._g({attrs:{"page":_vm.page,"numberOfPages":_vm.numberOfPages,"possibleItemsPerPage":_vm.possibleItemsPerPage,"listStyle":_vm.listStyle,"itemsPerPage":_vm.itemsPerPage,"itemsTotal":_vm.itemsTotal,"perPageLabel":_vm.$t('table.footer.plugin')}},_vm.$listeners))],1)]},proxy:true},(_vm.itemsLoading)?{key:"body",fn:function(){return [_c('NewPluginTableItemsSkeleton',{attrs:{"rowsNumber":_vm.itemsPerPage}})]},proxy:true}:null],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }