var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-iterator-card",class:[
    _vm.checked ? 'data-iterator-card--selected' : '',
    _vm.item.status === 'active' ? 'data-iterator-card--green' : '',
    _vm.item.update === 'available' && _vm.item.status === 'active'
      ? 'data-iterator-card--red'
      : '' ]},[_c('div',{staticClass:"data-iterator-card__checkbox-container"},[_c('checkbox',{attrs:{"checked":_vm.checkedComputed},on:{"change":_vm.handleCheckbox}})],1),_c('v-card',{attrs:{"elevation":"4"}},[_c('v-card-title',{staticClass:"d-flex justify-space-between align-center flex-nowrap"},[_c('h5',{staticClass:"item-title",domProps:{"innerHTML":_vm._s(_vm.item.title)}}),_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99","offset-overflow":"","nudge-bottom":"4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"ml-auto"},'div',attrs,false),on),[(_vm.item.update === 'available')?_c('v-icon',{attrs:{"color":"error","size":"16"}},[_vm._v(" $alertwarning ")]):_c('v-icon',{attrs:{"size":"16","color":_vm.item.status === 'active' ? 'primary' : 'gray darken-1'}},[_vm._v(" $checkcircle ")])],1)]}}])},[_c('span',{staticClass:"gray--text text--darken-2"},[(_vm.item.update ==='available')?_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.instance.plugin.update.available')))]):_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.instance.plugin.update.current')))])])])],1),_c('v-card-text',{staticClass:"p-3"},[_c('span',{ref:"text",staticClass:"truncate",domProps:{"innerHTML":_vm._s(_vm.getDescription(_vm.item.description))}}),(_vm.hasReadMoreButton)?_c('a',{staticClass:"truncate-text",on:{"click":function($event){return _vm.$emit('action-button-read-more', _vm.item)}}},[_c('span',{staticClass:"gray--text text--darken-2 font-weight-regular"},[_vm._v("...  ")]),_vm._v(" "+_vm._s(_vm.$t('button.readMore'))+" ")]):_vm._e()]),_c('v-card-actions',{staticClass:"pt-2 px-4"},[(_vm.item.hasInstallInProgress())?_c('div',{staticClass:"d-flex w-100 justify-space-between"},[_c('base-icon-button',{attrs:{"icon":"$update","text":_vm.$t('button.installing'),"loading":""}})],1):(_vm.item.hasUpdateInProgress())?_c('div',{staticClass:"d-flex w-100 justify-space-between"},[_c('base-icon-button',{attrs:{"icon":"$update","text":_vm.$t('button.updating'),"loading":""}})],1):_c('div',{staticClass:"d-flex w-100 justify-space-between"},[_c('div',{staticClass:"d-flex"},[(_vm.item.status === 'inactive')?_c('v-tooltip',{attrs:{"open-delay":"150","bottom":"","transition":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('base-icon-button',{attrs:{"icon":"$checkcircle","text":_vm.$t('button.activate')},nativeOn:{"click":function($event){return _vm.$emit('action-button-activate', _vm.item)}}})],1)]}}],null,false,1082521032)},[_c('span',{staticClass:"base--text"},[_vm._v(_vm._s(_vm.$t('button.plugin.activate')))])]):_c('v-tooltip',{attrs:{"open-delay":"150","bottom":"","transition":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('base-icon-button',{attrs:{"icon":"$xcircle","text":_vm.$t('button.disable')},nativeOn:{"click":function($event){return _vm.$emit('action-button-deactivate', _vm.item)}}})],1)]}}])},[_c('span',{staticClass:"base--text"},[_vm._v(_vm._s(_vm.$t('button.plugin.deactivate')))])]),(_vm.item.update === 'available')?_c('v-tooltip',{attrs:{"open-delay":"150","bottom":"","transition":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('base-icon-button',{attrs:{"color":"error","icon":"$update","text":_vm.$t('button.update')},nativeOn:{"click":function($event){return _vm.$emit('action-button-update', _vm.item)}}})],1)]}}],null,false,4168379870)},[_c('span',{staticClass:"base--text"},[_vm._v(_vm._s(_vm.$t('button.plugin.update')))])]):_vm._e()],1),_c('v-tooltip',{attrs:{"open-delay":"150","bottom":"","transition":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('base-icon-button',{attrs:{"icon":"$thrash","text":_vm.$t('button.remove')},nativeOn:{"click":function($event){return _vm.$emit('action-button-delete', _vm.item)}}})],1)]}}])},[_c('span',{staticClass:"base--text"},[_vm._v(_vm._s(_vm.$t('button.plugin.delete')))])])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }