var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"data-table",class:_vm.canBeSelected ? 'data-table--selectable' : '',attrs:{"elevation":"4"}},[_c('v-data-table',{staticClass:"elevation-0 custom-data-table",attrs:{"calculate-widths":"","headers":_vm.headers,"items":_vm.itemsLoading ? [] : _vm.items,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"search":_vm.searchTermComputed,"sort-by":_vm.sortBy.value,"sort-desc":_vm.sortDesc,"hide-default-footer":"","hide-default-header":"","server-items-length":_vm.itemsTotal},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([(_vm.itemsLoading)?{key:"body",fn:function(){return [_c('plugin-table-sort-skeleton'),_c('plugin-table-items-skeleton')]},proxy:true}:null,{key:"header",fn:function(ref){
var isMobile = ref.isMobile;
var props = ref.props;
var on = ref.on;
return [(!_vm.itemsLoading)?_c('table-custom-sort',_vm._g({attrs:{"isMobile":isMobile,"props":props,"v-on":on,"canBeSelected":true,"allSelected":_vm.allSelected},on:{"selectAll":_vm.handleSelectAll}},_vm.$listeners)):_vm._e()]}},{key:"no-data",fn:function(){return [_c('data-iterator-no-results-container',{attrs:{"searchTerm":_vm.searchTermComputed},on:{"clearAllFilters":_vm.clearFilters}})]},proxy:true},{key:"no-results",fn:function(){return [_c('data-iterator-no-results-container',{attrs:{"searchTerm":_vm.searchTermComputed},on:{"clearAllFilters":_vm.clearFilters}})]},proxy:true},{key:"item.select",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"item-bordered",class:[
                item.status === 'active' ? 'item-bordered--green' : '',
                item.update === 'available' ? 'item-bordered--red' : '' ]},[_c('checkbox',{attrs:{"checked":_vm.checkedItems.includes(item.name)},on:{"change":function($event){return _vm.handleSelectItem(item)}}})],1)]}},{key:"item.title",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"text-truncate",staticStyle:{"max-width":"450px"},domProps:{"innerHTML":_vm._s(_vm.getDescription(item.description))}}),(item.description.length > 100)?_c('a',{staticClass:"info--text font-weight-bold ml-1",on:{"click":function($event){return _vm.$emit('action-button-read-more', item)}}},[_vm._v(" "+_vm._s(_vm.$t('button.readMore')))]):_vm._e()])])]}},{key:"item.version",fn:function(ref){
              var item = ref.item;
return [(item.update !== 'available')?_c('v-icon',{attrs:{"color":"primary","size":"16"}},[_vm._v(" $alertok ")]):_c('v-icon',{attrs:{"color":"error","size":"16"}},[_vm._v("$alertwarning")]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.version))])]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [_c('status-label',{attrs:{"value":item.status == 'active' ? _vm.$t('message.status.activated') : _vm.$t('message.status.disabled'),"small":true,"status":item.status == 'active' ? 'primary' : 'gray'}})]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex actions-row"},[(item.status == 'inactive')?_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99","offset-overflow":"","nudge-bottom":"4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.$emit('action-button-activate', item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$checkcircle")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('button.plugin.activate')))])]):_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99","offset-overflow":"","nudge-bottom":"4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.$emit('action-button-deactivate', item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$xcircle")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('button.plugin.deactivate')))])]),_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99","offset-overflow":"","nudge-bottom":"4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":item.update === 'available' ? 'error' : undefined,"disabled":item.update !== 'available'},on:{"click":function($event){return _vm.$emit('action-button-update', item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$update")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('button.plugin.update')))])]),_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99","offset-overflow":"","nudge-bottom":"4px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.$emit('action-button-delete', item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("$thrash")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('button.plugin.delete')))])])],1)]}},{key:"footer",fn:function(){return [_c('div',[_c('data-iterator-footer',_vm._g({attrs:{"page":_vm.page,"numberOfPages":_vm.numberOfPages,"possibleItemsPerPage":_vm.possibleItemsPerPage,"listStyle":_vm.listStyle,"itemsPerPage":_vm.itemsPerPage,"itemsTotal":_vm.itemsTotal,"perPageLabel":_vm.$t('table.footer.plugin')}},_vm.$listeners))],1)]},proxy:true}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }