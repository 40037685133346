var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data-iterator-card",class:_vm.item.featured ? 'data-iterator-card--featured' : ''},[_c('v-card',{staticClass:"item",attrs:{"elevation":"4"}},[(_vm.item.featured)?_c('div',[_c('div',{staticClass:"featured-label"},[_c('span',{staticClass:"featured-label__text"},[_vm._v(_vm._s(_vm.$t('general.featured')))])])]):_vm._e(),_c('v-card-title',{staticClass:"d-flex justify-space-between align-start",staticStyle:{"flex-wrap":"nowrap"}},[_c('h5',{domProps:{"innerHTML":_vm._s(_vm.item.name)}}),(_vm.item.featured)?_c('v-tooltip',{attrs:{"bottom":"","nudge-bottom":"8px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2 primary-on-hover",attrs:{"size":"16","color":"gray lighten-1"}},'v-icon',attrs,false),on),[_vm._v(" $alertcircle ")])]}}],null,false,2029796185)},[_c('span',{staticClass:"text-base"},[_vm._v(_vm._s(_vm.$t('message.status.plugin.special')))])]):_vm._e()],1),_c('v-card-text',{staticClass:"p-3"},[_c('span',{ref:"text",staticClass:"truncate",domProps:{"innerHTML":_vm._s(_vm.getDescription(_vm.item.short_description || _vm.item.description))}}),(_vm.hasReadMoreButton)?_c('a',{staticClass:"truncate-text",on:{"click":function($event){return _vm.$emit('action-button-read-more', _vm.item)}}},[_c('span',{staticClass:"gray--text text--darken-2 font-weight-regular"},[_vm._v("... ")]),_vm._v(" "+_vm._s(_vm.$t('button.readMore'))+" ")]):_vm._e()]),_c('v-card-actions',{staticClass:"pt-0"},[_c('div',{staticClass:"d-flex align-center"},[(_vm.item.rating)?_c('div',{staticClass:"d-flex mr-3 align-center"},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"20","percent":_vm.item.rating / 100}},[_vm._v("$starIcon")]),_c('b',{staticClass:"p-4"},[_vm._v(_vm._s(Math.ceil((_vm.item.rating / 10) * 5) / 10))])],1):_vm._e(),(_vm.item.active_installs)?_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"20","elevation":"0","color":"gray lighten-1"}},[_vm._v("$installIcon")]),_c('b',{staticClass:"p-4"},[_vm._v(_vm._s(_vm.item.active_installs)+"+")])],1):_vm._e()]),_c('v-tooltip',{attrs:{"transition":"custom-tooltip","open-delay":"150","bottom":"","z-index":"99"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-auto",staticStyle:{"min-width":"38px","max-width":"38px"},attrs:{"elevation":"0","color":"primary","small":""},on:{"click":function($event){return _vm.$emit('action-button-install', _vm.item)}}},'v-btn',attrs,false),on),[(
                !_vm.item.hasInstallInProgress() && !_vm.item.hasUpdateInProgress()
              )?_c('v-icon',{staticClass:"mr-0",attrs:{"size":"18","color":"white"}},[_vm._v(" $installIcon ")]):_c('loader',{staticClass:"mr-0",attrs:{"color":"white","size":"24","noMargins":true}})],1)]}}])},[_c('span',{staticClass:"base--text"},[_vm._v(_vm._s(_vm.$t('button.plugin.install')))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }